import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

import { User } from '../../../../graphql/frontend-data-graphql';

@Component({
  selector: 'app-user-reactivate',
  templateUrl: './reactivate.component.html',
  styles: [],
  imports: [FormsModule, NzFormModule, ReactiveFormsModule, NzButtonModule, NzIconModule, NzGridModule, NzInputModule, TranslateModule]
})
export class ReactivationComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(NZ_MODAL_DATA) public user: User
  ) {
    this.form = this.fb.group({
      id: [],
      emailConfirmed: [null, [Validators.required, this.match()]]
    });
  }

  // see https://dev.to/jdgamble555/angular-confirm-password-validation-custom-validator-3pkl
  match(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent && !!control.parent.value && control.value === this.user.email ? null : { matching: true };
    };
  }

  ngOnInit(): void {
    console.log('Reactivation component initialized');
  }
}
